import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { trackDataV2 } from '../apis/tracking';
import Dishes from './Dishes';

function DishCourses(props) {
    const { business } = props;

    const onClickHandler = (e) => {
        let el = e.currentTarget;
        let svg = el.querySelector("svg");

        el.dataset.state === "open" ?
            svg.classList.remove("rotate-180") :
            svg.classList.add("rotate-180", "duration-200", "ease-in-out")

        el.dataset.state === "closed" &&
            trackDataV2(business.id, business.token, "courseopen", { id: parseInt(el.attributes.courseId.value), name: el.attributes.courseName.value }, false)
    }

    return (
        <Accordion.Root type="multiple" className="relative">
            {business.courses && business.courses.map((course, idx) =>
                <Accordion.Item value={`item-${idx}`} className="px-4">
                    <Accordion.Header>
                        <Accordion.Trigger courseId={course.id} courseName={course.name} onClick={onClickHandler} className="w-full flex my-6 px-3 py-4 flex items-center justify-between border shadow-xl rounded-full bg-white">
                            <span className="pl-2 font-bold font-argent capitalize">{course.name}</span>
                            <ChevronDownIcon aria-hidden />
                        </Accordion.Trigger>
                    </Accordion.Header>

                    <Accordion.Content>
                        <div className="px-3">
                            <Dishes id={course.id} />
                        </div>
                    </Accordion.Content>
                </Accordion.Item>)}
        </Accordion.Root>
    )
}

export default DishCourses;
