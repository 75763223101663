import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { useNavigate } from 'react-router';
import { trackDataV2 } from '../apis/tracking';
import { useTranslation } from 'react-i18next';

function InfoModal() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onClickHandler = () => {
        navigate("/voiello")
    }

    const onOpenModalHandler = () => {
        trackDataV2(1, "349fht9yw", "readstory", {}, false)
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger onClick={onOpenModalHandler} className="w-full p-3 px-4 font-hagerman text-lg text-voiello-darkBlue rounded-full border border-voiello-darkBlue">{t("infoModal.button_1")}</Dialog.Trigger>

            <Dialog.Portal>
                <Dialog.Overlay className="max-w-md m-auto fixed top-0 left-0 right-0 bottom-0 overflow-y-scroll bg-white">
                    <Dialog.Content className="bg-voiello-lightBlue p-10 py-4 space-y-4">
                        <div className="top-0 left-0 right-0 flex items-center space-x-4">
                            <Dialog.Trigger className="outline-none">
                                <img className="w-12" src="/assets/voiello/v_logo.svg" />
                            </Dialog.Trigger>

                            <button className="w-full py-4 px-4 text-white font-hagerman font-bold rounded-full bg-voiello-darkBlue shadow-xl" onClick={onClickHandler}>{t("infoModal.button_2")}</button>
                        </div>

                        <p className="text-4xl font-argent text-voiello-purple">{t("infoModal.p._1")} <br /> {t("infoModal.p._2")}</p>

                        <div className="font-marcia text-voiello-darkBlue">{t("infoModal.div_1")}</div>
                        <div className="font-marcia text-voiello-darkBlue">{t("infoModal.div_2")}</div>
                        <div className="font-marcia text-voiello-darkBlue">{t("infoModal.div_3")}</div>
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default InfoModal;
