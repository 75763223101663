import React, { useEffect } from 'react';
import searchClient from '../apis/search';
import { useTranslation } from 'react-i18next';

function SearchBar(props) {
    const { className, searchText, setSearchText, setSearchResults, setLoading, type } = props;

    const { t } = useTranslation();

    useEffect(() => {
        async function fetchSearch() {
            const result = await searchClient.searchOnGroup(searchText, process.env.REACT_APP_NAMESPACE);
            setSearchResults(result);
            setLoading(false);
        }

        if (searchText != "" && searchText != null) {
            setLoading(true);
            fetchSearch();
        }
    }, [searchText]);

    return (
        <div>
            <input
                className={className}
                placeholder={type == "restaurant" ? t("businessesWrapperTabs.searchBar.input_1") : t("businessesWrapperTabs.searchBar.input_2")}
                onChange={e => (setSearchText(e.target.value))} />
        </div>
    )
}

export default SearchBar;
