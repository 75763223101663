import { useState, useMemo } from 'react';
import network from '../../apis/network';
import position from '../../apis/position';

function useNetwork(props) {
    const { userPosition, city, pacchetti } = props;

    const [business, setBusiness] = useState({ restaurants: [], shops: [] });

    useMemo(() => {
        async function handleBusinessLoad() {
            let networkType = position.getPosition(userPosition);

            const response = await network.doGetGroupRestaurants(process.env.REACT_APP_NAMESPACE, networkType);

            const restaurants = {
                1: response.filter(restaurant => restaurant.category == "restaurant" && restaurant.voiello_network == 1),
                3: response.filter(restaurant => restaurant.category == "restaurant" && restaurant.voiello_network == 3)
            };

            const shops = {
                1: response.filter(shop => shop.category == "shop" && shop.voiello_network == 1),
                3: response.filter(shop => shop.category == "shop" && shop.voiello_network == 3)
            };

            setBusiness({ restaurants, shops, networkType });
        }

        handleBusinessLoad();
    }, [userPosition]);

    return business ? business : [];
}

export default useNetwork;
