import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

function useGeoPosition() {
    const [position, setPosition] = useState({ lat: "", lng: "", msg: "" })
    const { t } = useTranslation();

    function errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        setPosition(prev => ({ ...prev, msg: t("geoposition") }))
    }

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) =>
                setPosition({ lat: pos.coords.latitude, lng: pos.coords.longitude }), errors, options)
        }
    }, [])

    return position && position;
}

export default useGeoPosition;
