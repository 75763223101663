import * as Dialog from "@radix-ui/react-dialog";
import { useMemo, useState } from "react";
import { trackDataV2 } from "../apis/tracking";
import ImageGallery from 'react-image-gallery';

function PhotoModal(props) {
    const { business, image, idx } = props;
    const [images, setImages] = useState([]);

    const onClickHandler = () => {
        trackDataV2(business.id, business.token, "viewbusinessphoto", {}, false);
    }

    useMemo(() => {
        let aux = [];
        business.images_gallery.map(image => aux.push({ original: image.big, thumbnail: image.small }));
        setImages(aux)
    }, [])

    return (
        <Dialog.Root>
            <Dialog.Trigger onClick={onClickHandler}>
                <div className="px-2">
                    <p className="px-10"></p>
                    <img src={image.small} />
                </div>
            </Dialog.Trigger>

            <Dialog.Portal>
                <Dialog.Overlay className="fixed top-0 left-0 right-0 bottom-0 grid place-items-center overflow-y-auto bg-black">
                    <Dialog.Content>
                        <div className="flex justify-center items-center">
                            <Dialog.Trigger className="absolute top-0 right-0 mx-8 my-6 rounded-full p-2 bg-white shadow-2xl shadow-black">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368" /></svg>
                            </Dialog.Trigger>
                        </div>

                        <ImageGallery showPlayButton={false} showFullscreenButton={false} startIndex={idx} items={images} />
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

export default PhotoModal;


