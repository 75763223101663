import formats from "../utils/formats";
import apiClient from "./api-client";
import { RESTAURANTSENDPOINT } from "./constants";

async function doGetGroupRestaurants(network, networkType = "", city = "", pacchetti = "") {
    var response = await apiClient.doGet(formats.formatUrl(RESTAURANTSENDPOINT, { network, network_type: networkType, city, pacchetti }));

    return response;
}

function getNetworkList() {
    const list = {
        procida: 1,
        ischia: 3
    }

    return list;
}

export default { doGetGroupRestaurants, getNetworkList }
