import React, { useEffect } from "react";
import BookingHandler from "../components/BookingHandler";
import BusinessTabs from "../components/BusinessTabs";
import formats from "../utils/formats";
import Gallery from "../components/Gallery";
import { trackDataV2 } from "../apis/tracking";
import { useGetBusiness } from "../logic/contexts/BusinessContext";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Business() {
    const { business, setToken } = useGetBusiness();

    const { token } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        setToken(token);
    }, []);

    useEffect(() => {
        trackDataV2(business.id, business.token, "openbusiness", {}, false);

        if (business.id && "production" === process.env.NODE_ENV) {
            const scriptURL = "https://script.google.com/macros/s/AKfycbymFGkOt9lpMKBnxr1sjSwYQURxiVjkPLzeRKdi4QxMGF1Kf8l6SsmLHNlh6ok5hYaS/exec";
            const formData = new FormData();

            formData.append("Date", new Date());
            formData.append("Event", "openbusiness");
            formData.append("User_agent", navigator.userAgent);
            fetch(scriptURL, { method: "POST", body: formData });
        }

    }, [business]);

    const onClickHandler = () => {
        trackDataV2(business.id, business.token, "phonecall", {}, false);
    }

    return (
        <div>
            {business &&
                <div className="relative">
                    <div className="relative pt-2 pb-1 bg-voiello-lightBlue">
                        <div className="px-6 space-y-6">
                            <Link to="/voiello" className="absolute top-0 right-0 m-6 p-2 w-10 h-10 flex items-center block rounded-full bg-white shadow-2xl shadow-black">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 text-voiello-gold" viewBox="0 0 512 512"><path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path></svg>
                            </Link>

                            <p className="text-voiello-darkBlue text-4xl pr-10 font-argent">{business.name}</p>

                            <p className="text-voiello-darkBlue font-marcia">{formats.truncate(business.description, 150)}</p>

                            <div className="flex items-center">
                                <p className="text-voiello-darkBlue font-marcia">{business.address}</p>
                            </div>

                            <div className="py-4">
                                <a className="flex items-center justify-center w-full p-3 py-4 space-x-6 rounded-full bg-white shadow-xl" href={`tel:${business.phone_number}`} onClick={onClickHandler} style={{
                                    right: "2rem",
                                    bottom: "5%",
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 fill-voiello-darkBlue" viewBox="0 0 512 512"><path d="M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0128.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 01-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 01391 480z" /></svg>
                                    <p className="text-voiello-darkBlue font-hagerman">{t("business.p")}</p>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="relative pb-20">
                        {business && business.images_gallery && <Gallery business={business} />}

                        <BusinessTabs business={business} />
                    </div>

                    <BookingHandler business={business} />
                </div>
            }
        </div>
    );
}

export default Business;
