import formats from "../utils/formats";
import apiClient from "./api-client";
import { SEARCHENDPOINT, SEARCHFOODTYPENDPOINT } from "./constants";

async function searchOnGroup(input, group) {
    var response = apiClient.doGet(formats.formatUrl(SEARCHENDPOINT, { "network": group, "name": input }));

    return response;
}

async function searchFoodTypeOnGroup(type, network, search) {
    var response = await apiClient.doGet(formats.formatUrl(SEARCHFOODTYPENDPOINT, { network, food_type: search }));

    return response;
}

export default { searchOnGroup, searchFoodTypeOnGroup };
