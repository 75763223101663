import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfoModal from "../components/InfoModal.jsx";
import { trackDataV2 } from "../apis/tracking.js";

function Group() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        trackDataV2(1, "349fht9yw", "viewhome", {}, false);
    }, [])

    const onClickHandler = () => {
        trackDataV2(1, "349fht9yw", "discoverhow", {}, false);
    }

    return (
        <div className="voiello-splash text-center">
            <div className="bg-voiello-lightBlue">
                <img className="w-full" src="/assets/voiello/main-logo.png" />

                <img className="m-auto py-4 w-36" src="/assets/voiello/logo.png" />

                <div className="space-y-6 px-8 pb-6">
                    <p className="text-4xl text-voiello-darkBlue font-argent">{t("group.p_0")}</p>

                    <button onClick={() => navigate("/voiello")} className="w-full p-3 px-4 font-hagerman text-lg text-white rounded-full bg-voiello-red">{t("group.button_1")}</button>

                    <p className="pt-2 text-left text-voiello-darkBlue font-marcia font-bold">{t("group.p_1")}</p>

                    <InfoModal />
                </div>
            </div>

            <div className="space-y-6 pb-6">
                <div style={{ backgroundImage: "url('/assets/voiello/separator_1.png')" }} className="py-6 bg-cover"></div>

                <div className="px-8 space-y-6">
                    <p className="text-left text-voiello-darkBlue font-marcia">{t("group.p_2")}</p>

                    <a className="block w-full p-3 px-4 font-hagerman text-lg text-white rounded-full bg-voiello-darkBlue" href="https://www.voiello.it/contatti/" target="_blank" rel="noopener">{t("group.a_1")}</a>
                </div>
            </div>

            <div className="mt-4 pb-6 bg-voiello-lightBlue">
                <img className="w-full bg-cover" src="/assets/voiello/separator_2.png" />

                <div className="space-y-8 px-8">
                    <p className="pt-6 text-left font-marcia text-voiello-darkBlue">{t("group.p_3")}</p>
                    <a className="block w-full p-3 px-4 font-hagerman text-lg text-white rounded-full bg-voiello-darkBlue" href="https://quomi.it/box-voiello-procida" target="_blank" rel="noopener" onClick={onClickHandler}>{t("group.a_2")}</a>
                    <p className="px-4 font-marcia text-white">{t("group.p_4")}</p>
                </div>
            </div>
        </div>
    );
}

export default Group;
