import formats from "../utils/formats";
import apiClient from "./api-client";
import { GMAPENDPOINT } from "./constants";
import kmlIschia from "./places/ischia.json";
import kmlProcida from "./places/procida.json";

async function getDistanceFromTwoPoints(origin, destination) {
    var response = await apiClient.doGet(formats.formatUrl(GMAPENDPOINT, { oLat: origin.lat, oLng: origin.lng, dLat: destination.lat, dLng: destination.lng }));

    response = response && response.rows[0] && response.rows[0].elements[0].distance.text;

    return response;
}

function checkAddressIsInArea(address, area) {
    var position = new window.google.maps.LatLng(address.lat, address.lng);

    for (var i = 0; i < area.length; i++) {
        var obj = area[i];
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop) && obj[prop] !== null && !isNaN(obj[prop])) {
                obj[prop] = +obj[prop];
            }
        }
    }

    const areaPoligon = new window.google.maps.Polygon({ paths: area });

    return window.google.maps.geometry.poly.containsLocation(position, areaPoligon);
}

function getPosition(userPosition) {
    let networkType = checkAddressIsInArea(userPosition, kmlProcida) ? 1 : "";

    if (networkType != 1) {
        networkType = checkAddressIsInArea(userPosition, kmlIschia) ? 3 : "";
    }

    return networkType;
}

export default { getDistanceFromTwoPoints, getPosition };
