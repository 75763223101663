import * as Dialog from '@radix-ui/react-dialog';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'react-i18next';
import { trackDataV2 } from '../apis/tracking';

function InfoMapModal(props) {
    const { isShop, distance, business } = props;

    const onClickHandler = () => {
        trackDataV2(business.id, business.token, "showbusinessmap", {}, false);
    }

    const { t } = useTranslation();

    return (
        <Dialog.Root>
            <Dialog.Trigger onClick={onClickHandler}>
                {!isShop ? distance ? distance : "..." : ""}
                {isShop &&
                    <div className="p-2 rounded-full border bg-voiello-darkBlue">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 fill-white stroke-white" viewBox="0 0 512 512"><path d="M313.27 124.64L198.73 51.36a32 32 0 00-29.28.35L56.51 127.49A16 16 0 0048 141.63v295.8a16 16 0 0023.49 14.14l97.82-63.79a32 32 0 0129.5-.24l111.86 73a32 32 0 0029.27-.11l115.43-75.94a16 16 0 008.63-14.2V74.57a16 16 0 00-23.49-14.14l-98 63.86a32 32 0 01-29.24.35zM328 128v336M184 48v336" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /></svg>
                    </div>}
            </Dialog.Trigger>

            <Dialog.Portal>
                <Dialog.Overlay>
                    <Dialog.Content className="max-w-md m-auto fixed top-0 left-0 right-0 bottom-0 overflow-y-scroll bg-white">
                        <div className="space-y-4 h-2/5 px-8">
                            <Dialog.Trigger className="outline-none float-right mt-2 rounded-full border-[0.5px] p-2 bg-white shadow-2xl shadow-black">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 text-voiello-gold" viewBox="0 0 512 512"><path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368" /></svg>
                            </Dialog.Trigger>

                            <p className="text-4xl font-argent text-voiello-darkBlue">{business.name}</p>
                            <div className="flex items-center">
                                <p className="font-marcia text-voiello-darkBlue">{business.address}</p>
                                <p className="w-20 ml-2 text-center text-sm font-marcia border rounded-xl">{distance}</p>
                            </div>
                            <button className="w-full py-3 px-20 text-center text-white font-hagerman bg-voiello-darkBlue rounded-full" onClick={() => window.open(`https://maps.google.com/maps/place/${business.latitude},${business.longitude}`)}>{t("infoMapModal.button")}</button>
                        </div>

                        <div className="h-3/5 w-full pt-10">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.REACT_APP_NAMESPACE }}
                                defaultCenter={{ lat: business.latitude, lng: business.longitude }}
                                defaultZoom={15}>
                                <div className="w-20" lat={business.latitude} lng={business.longitude}>
                                    <svg className="w-6" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="36px" height="36px" viewBox="-4 0 36 36" version="1.1"><g id="Vivid.JS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)"><g id="Icons" transform="translate(37.000000, 169.000000)"><g id="map-marker" transform="translate(78.000000, 468.000000)"><g transform="translate(10.000000, 6.000000)"><path d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z" id="Shape" fill="#FF6E6E" /><circle id="Oval" fill="#0C0058" fillRule="nonzero" cx="14" cy="14" r="7" /></g></g></g></g></g></svg>
                                    <p className="font-bold text-sm">{business.name}</p>
                                </div>
                            </GoogleMapReact>
                        </div>
                    </Dialog.Content>

                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    )
}

export default InfoMapModal;
