import * as Dialog from '@radix-ui/react-dialog';
import { useGetBusiness } from '../logic/contexts/BusinessContext';
import { trackDataV2 } from '../apis/tracking';

function DrinkModal(props) {
    const { drink } = props;

    const { business } = useGetBusiness();

    const onClickHandler = () => {
        trackDataV2(business.id, business.token, "drinkview", { id: drink.id, name: drink.name }, false)
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger onClick={onClickHandler} className="mb-4 p-3 w-full border shadow rounded-xl bg-white">
                <div className="flex">
                    {drink.image_path && <img className="w-28 h-28 object-cover rounded" src={drink.image_path} alt="" />}
                    <div className="px-3 space-y-4 text-left">
                        <p className="capitalize font-argent text-voiello-darkBlue">{drink.name}</p>
                        {drink.description && <p className="capitalize text-xs text-gray-400 font-marcia">{drink.description}</p>}
                        {/* {drink.price > 0 && <p className="capitalize italic text-gray-400 font-marcia">{drink.price}€</p>} */}
                    </div>
                </div>
            </Dialog.Trigger>

            <Dialog.Portal>
                <Dialog.Overlay>
                    <Dialog.Content className="max-w-md m-auto fixed top-0 left-0 right-0 bottom-0 overflow-y-scroll bg-white">
                        <div className="flex justify-center items-center">
                            <Dialog.Trigger className="outline-none absolute top-0 left-0 mx-8 my-6 rounded-full p-2 bg-white shadow-2xl shadow-black">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                            </Dialog.Trigger>

                            <img className="w-full max-h-52 object-cover" alt="" src={drink.image_path} />
                        </div>

                        <div className="p-10 py-8 space-y-4">
                            <div className="space-y-6">
                                <p className="pt-12 text-3xl font-argent text-voiello-darkBlue capitalize">{drink.name}</p>

                                {/* <p className="font-marcia text-gray-600">{drink.price}€</p> */}

                                <span className="flex w-1/6 h-1 bg-voiello-gold"></span>

                                <p className="text-sm text-voiello-darkBlue font-marcia">{drink.description}</p>
                            </div>
                        </div>
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default DrinkModal;
