import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import Group from "./pages/Group";
import BusinessesWrapper from "./pages/BusinessesWrapper";
import Business from "./pages/Business";
import "./App.css";

function AppRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<Group />} />
            <Route exact path="/voiello" element={<BusinessesWrapper />} />
            <Route exact path="/voiello/:token/menu" element={<Business />} />
        </Routes>
    );
}

export default AppRoutes;
