import * as Dialog from '@radix-ui/react-dialog';
import { useGetBusiness } from '../logic/contexts/BusinessContext';
import { trackDataV2 } from '../apis/tracking';
import IngredientList from './IngredientList';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import formats from '../utils/formats';

function DishModal(props) {
    const { dish } = props;

    const { business } = useGetBusiness();
    const { t } = useTranslation();

    const onClickHandler = () => {
        trackDataV2(business.id, business.token, "dishview", { id: dish.id, name: dish.name }, false)
    }

    const isVoiello = dish.name.match(/voiello/gi);

    return (
        <Dialog.Root>
            <Dialog.Trigger onClick={onClickHandler} className={clsx("relative mb-4 p-3 w-full border border-voiello-lightBlue rounded-xl bg-white", { "bg-voiello-lightBlue": isVoiello })}>
                <div className="flex">
                    {dish.image_path && <img src={dish.image_path} className="w-28 h-28 object-cover rounded" alt="" />}
                    <div className="px-3 space-y-4 text-left">
                        <div>
                            <p className="capitalize font-argent text-voiello-darkBlue">{dish.name}</p>
                            {dish.description && <p className="pt-2 capitalize text-xs font-marcia">{formats.truncate(dish.description, 60)}</p>}
                        </div>
                        <div className="flex">
                            {dish.allergens.map(allergen =>
                                <img className="w-5 mx-1" alt="" src={"/assets/" + allergen.image_url} />
                            )}
                        </div>
                        {/* {dish.price > 0 && <p className="text-gray-400 font-marcia italic">{dish.price}€</p>} */}
                    </div>
                </div>
                {isVoiello && <img className="absolute bottom-0 right-0 m-3 w-8" src="/assets/voiello/v_logo.svg" />}
            </Dialog.Trigger>

            <Dialog.Portal>
                <Dialog.Overlay>
                    <Dialog.Content className="max-w-md w-full m-auto fixed top-0 left-0 right-0 bottom-0 overflow-y-scroll bg-white">
                        <div className="flex justify-center items-center">
                            <Dialog.Trigger className="outline-none  absolute top-0 left-0 mx-8 my-6 rounded-full p-2 bg-white shadow-2xl shadow-black">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                            </Dialog.Trigger>

                            {dish.has_video == true ?
                                <iframe className="w-full h-52" src={dish.video_url} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> :
                                <img className="w-full max-h-52 object-cover" alt="" src={dish.image_path} />}
                        </div>

                        <div className="p-10 py-8 space-y-4">
                            <div className="space-y-6">
                                <p className="pt-12 text-3xl font-argent text-voiello-darkBlue capitalize">{dish.name}</p>
                                {/* <p className="text-gray-600 font-marcia">{dish.price}€</p> */}
                                <span className="flex w-1/6 h-1 bg-voiello-gold"></span>

                                <p className="text-sm text-voiello-darkBlue font-marcia">{dish.description}</p>

                                <p className="text-voiello-darkBlue font-extrabold font-marcia">{t("dishModal.p_1")}</p>
                                <ul className="text-voiello-darkBlue font-marcia">
                                    {dish.ingredients.map((ingredient, i) => <IngredientList ingredient={ingredient} key={i} />)}
                                </ul>

                                {dish.has_allergens ?
                                    <div className="allergens-warning flex items-center justify-center py-6 pr-4 border-t border-t-gray-200">
                                        <svg className="w-6 flex-none mr-4 fill-current text-mycia-red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path xmlns="http://www.w3.org/2000/svg" d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" /></svg>
                                        <div>
                                            <p className="text-sm font-marcia">{t("dishModal.p_2")}</p>
                                        </div>
                                    </div>
                                    : null}

                                {dish.is_frozen ?
                                    <div className="frozen-warning flex items-center justify-center py-6 pr-4 border-t border-t-gray-200">
                                        <img className="flex-none mr-4 text-mycia-red" alt="" />
                                        <div>
                                            <p className="text-sm">{t("dishModal.p_3")}</p>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default DishModal;
