import React, { useEffect, useState } from 'react';
import formats from '../utils/formats';
import positionLogic from "../apis/position";
import InfoMapModal from './InfoMapModal';

function Tile(props) {
    const { isShop, business: restaurant, userPosition, index: i } = props;

    const [distance, setDistance] = useState("");

    useEffect(async () => {
        // const savedDistance = sessionStorage.getItem(restaurant.token);
        // if (savedDistance) {
        // setDistance(savedDistance);
        // } else {
        const res = await positionLogic.getDistanceFromTwoPoints({ lat: userPosition.lat, lng: userPosition.lng }, { lat: restaurant.latitude, lng: restaurant.longitude });
        // sessionStorage.setItem(restaurant.token, res);
        setDistance(res);
        // }
    }, [userPosition]);

    return (
        <div className="my-8 px-3 py-1 flex items-center justify-between bg-white border border-voiello-lightBlue rounded-full" key={i}>
            <img src={restaurant.image_url || restaurant.cover_image} alt="Immagine rappresentativa del ristorante" className="object-cover w-14 h-14 border border-grey-300 rounded-full" />

            <div className="h-full">
                <div className="space-y-2">
                    <p className="uppercase text-voiello-darkBlue font-argent font-extrabold leading-tight">{formats.truncate(restaurant.name, 18)}</p>
                    <div className="ml-1 flex items-center">
                        <p className="w-2/3 text-clip font-marcia text-sm text-voiello-gray">{formats.truncate(restaurant.address, 28)}</p>
                        <p className="w-1/3 mr-2 text-center text-sm text-voiello-gold font-marcia border border-voiello-gold rounded-xl">
                            <InfoMapModal distance={distance} business={restaurant} />
                        </p>
                    </div>
                </div>
            </div>

            {isShop ?
                <InfoMapModal isShop={isShop} distance={distance} business={restaurant} /> :
                <a href={formats.formatUrl("/voiello/:token/menu", { "token": restaurant.token })} className="p-2 block text-white bg-voiello-darkBlue rounded-full shadow-2xl">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                </a>}
        </div>
    )
}

export default Tile;
