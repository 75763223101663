import React, { useEffect } from "react";
import useNetwork from "../logic/hooks/useNetwork";
import useGeoPosition from "../logic/hooks/useGeoPosition";
import { trackDataV2 } from "../apis/tracking";
import { Link } from "react-router-dom";
import InfoModalOnWrapper from "../components/InfoModalOnWrapper";
import BusinessesWrapperTabs from "../components/BusinessesWrapperTabs";
import BusinessesWrapperTabsGeo from "../components/BusinessesWrapperTabsGeo";

function BusinessesWrapper() {
    const userPosition = useGeoPosition();

    const { restaurants, shops, networkType } = useNetwork({ userPosition });

    useEffect(() => {
        //tracking restaurants because default tab is restaurant
        trackDataV2(1, "349fht9yw", "showrestaurants", {}, false);
    }, []);

    return (
        <div>
            <div className="bg-voiello-lightBlue">
                <div className="flex justify-between items-start h-80 px-6 py-8 bg-cover bg-center" style={{ backgroundImage: "url('/assets/voiello/wrapper-logo.png')" }}>
                    <InfoModalOnWrapper />

                    <Link to="/" className="block p-2 bg-white shadow-2xl shadow-black rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6" viewBox="0 0 512 512"><path d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /></svg>
                    </Link>
                </div>
            </div>

            <div className="group-list relative bg-white pb-20">
                {userPosition.msg &&
                    <p className="mt-4 text-sm text-center text-red-400">{userPosition.msg}</p>}

                <div className="px-4">
                    {networkType ?
                        <BusinessesWrapperTabsGeo restaurants={restaurants} shops={shops} userPosition={userPosition} networkType={networkType} /> :
                        <BusinessesWrapperTabs restaurants={restaurants} shops={shops} userPosition={userPosition} />}
                </div>
            </div>
        </div>
    )
}

export default BusinessesWrapper;
